exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accounts-type-js": () => import("./../../../src/pages/accounts-type.js" /* webpackChunkName: "component---src-pages-accounts-type-js" */),
  "component---src-pages-all-markets-js": () => import("./../../../src/pages/all-markets.js" /* webpackChunkName: "component---src-pages-all-markets-js" */),
  "component---src-pages-career-js": () => import("./../../../src/pages/career.js" /* webpackChunkName: "component---src-pages-career-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/coming-soon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-company-js": () => import("./../../../src/pages/company.js" /* webpackChunkName: "component---src-pages-company-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-copy-trading-js": () => import("./../../../src/pages/copy-trading.js" /* webpackChunkName: "component---src-pages-copy-trading-js" */),
  "component---src-pages-crypto-js": () => import("./../../../src/pages/crypto.js" /* webpackChunkName: "component---src-pages-crypto-js" */),
  "component---src-pages-ctrader-js": () => import("./../../../src/pages/ctrader.js" /* webpackChunkName: "component---src-pages-ctrader-js" */),
  "component---src-pages-data-deletion-js": () => import("./../../../src/pages/data-deletion.js" /* webpackChunkName: "component---src-pages-data-deletion-js" */),
  "component---src-pages-education-js": () => import("./../../../src/pages/education.js" /* webpackChunkName: "component---src-pages-education-js" */),
  "component---src-pages-energies-js": () => import("./../../../src/pages/energies.js" /* webpackChunkName: "component---src-pages-energies-js" */),
  "component---src-pages-etf-js": () => import("./../../../src/pages/etf.js" /* webpackChunkName: "component---src-pages-etf-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-forex-js": () => import("./../../../src/pages/forex.js" /* webpackChunkName: "component---src-pages-forex-js" */),
  "component---src-pages-funding-js": () => import("./../../../src/pages/funding.js" /* webpackChunkName: "component---src-pages-funding-js" */),
  "component---src-pages-hero-js": () => import("./../../../src/pages/hero.js" /* webpackChunkName: "component---src-pages-hero-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-indices-js": () => import("./../../../src/pages/indices.js" /* webpackChunkName: "component---src-pages-indices-js" */),
  "component---src-pages-legal-cookie-js": () => import("./../../../src/pages/legal/cookie.js" /* webpackChunkName: "component---src-pages-legal-cookie-js" */),
  "component---src-pages-legal-js": () => import("./../../../src/pages/legal.js" /* webpackChunkName: "component---src-pages-legal-js" */),
  "component---src-pages-legal-privacy-js": () => import("./../../../src/pages/legal/privacy.js" /* webpackChunkName: "component---src-pages-legal-privacy-js" */),
  "component---src-pages-metals-js": () => import("./../../../src/pages/metals.js" /* webpackChunkName: "component---src-pages-metals-js" */),
  "component---src-pages-mt-4-js": () => import("./../../../src/pages/mt4.js" /* webpackChunkName: "component---src-pages-mt-4-js" */),
  "component---src-pages-mt-4-webtrader-js": () => import("./../../../src/pages/mt4-webtrader.js" /* webpackChunkName: "component---src-pages-mt-4-webtrader-js" */),
  "component---src-pages-mt-5-js": () => import("./../../../src/pages/mt5.js" /* webpackChunkName: "component---src-pages-mt-5-js" */),
  "component---src-pages-mt-5-webtrader-js": () => import("./../../../src/pages/mt5-webtrader.js" /* webpackChunkName: "component---src-pages-mt-5-webtrader-js" */),
  "component---src-pages-partners-js": () => import("./../../../src/pages/partners.js" /* webpackChunkName: "component---src-pages-partners-js" */),
  "component---src-pages-platforms-js": () => import("./../../../src/pages/platforms.js" /* webpackChunkName: "component---src-pages-platforms-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-professional-qualification-js": () => import("./../../../src/pages/professional-qualification.js" /* webpackChunkName: "component---src-pages-professional-qualification-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "component---src-pages-shares-js": () => import("./../../../src/pages/shares.js" /* webpackChunkName: "component---src-pages-shares-js" */),
  "component---src-pages-spreads-and-fees-js": () => import("./../../../src/pages/spreads-and-fees.js" /* webpackChunkName: "component---src-pages-spreads-and-fees-js" */),
  "component---src-pages-swap-free-js": () => import("./../../../src/pages/swap-free.js" /* webpackChunkName: "component---src-pages-swap-free-js" */),
  "component---src-pages-trading-tools-js": () => import("./../../../src/pages/trading-tools.js" /* webpackChunkName: "component---src-pages-trading-tools-js" */),
  "component---src-pages-trading-view-js": () => import("./../../../src/pages/trading-view.js" /* webpackChunkName: "component---src-pages-trading-view-js" */),
  "component---src-pages-vps-js": () => import("./../../../src/pages/vps.js" /* webpackChunkName: "component---src-pages-vps-js" */)
}

